var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Link"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeButtonHref) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("When the ")]), _c('code', [_vm._v("href")]), _c('span', [_vm._v(" is set to any other value (or the ")]), _c('code', [_vm._v("to")]), _c('span', [_vm._v(" prop is used), ")]), _c('code', [_vm._v("role=\"button\"")]), _c('span', [_vm._v(" will not be added, nor will the keyboard event listeners be enabled.")])]), _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('span', [_c('strong', [_vm._v("Note:")]), _vm._v(" Onclick of the Link button will redirect you to another page.")])])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "href": "https://pixinvent.com/"
    }
  }, [_vm._v(" String Literal ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary",
      "target": "_blank",
      "href": "https://pixinvent.com/"
    }
  }, [_vm._v(" Open New Tab ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }