var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('button-filled'), _c('button-border'), _c('button-flat'), _c('button-gradient'), _c('button-relief'), _c('button-rounded'), _c('button-icons'), _c('button-icons-only'), _c('button-size'), _c('button-checkbox-radio'), _c('button-router'), _c('button-link'), _c('button-block')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }